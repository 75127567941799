import React from 'react'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
// import { graphql } from 'gatsby'

const PostTemplate = ({postSlug, postId, siteAddress}) => {
    
    let disqusConfig = {
      url: `https://flamingod.com/${postSlug}/`,
      identifier: postId,
      title: postSlug,
    }
    return (
      <div>
        <CommentCount config={disqusConfig} placeholder={'...'} />
        <Disqus config={disqusConfig} />
      </div>
    )
  }
  
  export default PostTemplate